<template>

	<div class="body theme-cyan">

		<!-- Top Bar -->
		<nav class="navbar">
			<div class="col-12">
				<div class="navbar-header">
					<a href="javascript:void(0);" class="bars"></a>
					<a class="navbar-brand" href="/"><img src="assets/images/logo.svg" width="35" alt="Compass"><span
							class="m-l-10">大冶招商局信息填报</span></a>
				</div>
				<ul class="nav navbar-nav navbar-left">
					<li><a href="javascript:void(0);" class="ls-toggle-btn" data-close="true"><i
								class="zmdi zmdi-swap"></i></a></li>
				</ul>
				<ul class="nav navbar-nav navbar-right">
					<li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown"
							role="button"><i class="zmdi zmdi-notifications"></i>
							<div class="notify"><span class="heartbit"></span><span class="point"></span></div>
						</a>
						<ul class="dropdown-menu dropdown-menu-right slideDown">
							<li class="header">通知</li>
							<li class="body">
								<ul class="menu list-unstyled">

								</ul>
							</li>
							<li class="footer"> <a href="javascript:void(0);">View All Notifications</a> </li>
						</ul>
					</li>
					<li><a href="javascript:void(0)" @click="logout" class="mega-menu" data-close="true"><i
								class="zmdi zmdi-power"></i></a>
					</li>
					<li class="">
						<a href="javascript:void(0);" class="js-right-sidebar" data-close="true">
							<i class="zmdi zmdi-settings zmdi-hc-spin"></i>
						</a>
					</li>
				</ul>
			</div>
		</nav>

		<!-- Left Sidebar -->
		<aside id="leftsidebar" class="sidebar">
			<div class="menu">
				<ul class="list">
					<li>
						<div class="user-info">
							<div class="image">
								<a href="/">
									<img v-if="user.sex=='男'" src="assets/images/man.png" alt="User"></a>
								<img v-if="user.sex=='女'" src="assets/images/woman.png" alt="User"></a>
							</div>
							<div class="detail">
								<h4>{{user.name}}</h4>
								<small class="role-name">{{role.name}}</small>
							</div>
							<a href="javascript:void(0)" title="Events"><i class="zmdi zmdi-calendar"></i></a>
							<a href="javascript:void(0)" title="Inbox"><i class="zmdi zmdi-email"></i></a>
							<a href="javascript:void(0)" title="Contact List"><i
									class="zmdi zmdi-account-box-phone"></i></a>
							<a href="javascript:void(0)" title="Chat App"><i class="zmdi zmdi-comments"></i></a>
							<a href="javascript:void(0)" @click="logout()" title="Sign out"><i
									class="zmdi zmdi-power"></i></a>
						</div>
					</li>


					<li> <a href="javascript:void(0);" class="menu-toggle"><i
								class="zmdi zmdi-apps"></i><span>信息填报</span> </a>
						<ul class="ml-menu">
							<li v-for="(item,index) in resouces"><a href="javascript:void(0)"
									@click="go(item.url)">{{item.name}}</a></li>
						</ul>
					</li>

					<li>
						<a href="javascript:void(0);" class="menu-toggle">
							<i class="zmdi zmdi-delicious"></i><span>所有单位填报信息汇总</span>
						</a>
						<ul class="ml-menu">
							<li>
								<a href="javascript:void(0)" @click="go('/collect')">填报信息汇总</a>
							</li>
						</ul>
					</li>

					<li v-if="role.type==='system'">
						<a href="javascript:void(0);" class="menu-toggle">
							<i class="zmdi zmdi-account-add"></i><span>基础数据</span>
						</a>
						<ul class="ml-menu">
							<li>
								<a href="javascript:void(0)" @click="go('/manager-dept')">部门管理</a>
							</li>
							<li>
								<a href="javascript:void(0)" @click="go('/manager-user')">用户管理</a>
							</li>
							<li>
								<a href="javascript:void(0)" @click="go('/manager-role')">权限管理</a>
							</li>
						</ul>
					</li>



				</ul>
			</div>
		</aside>

		<!-- Main Content -->
		<section class="content">

			<router-view></router-view>

		</section>
	</div>

</template>

<script>
	import $ from 'jquery'
	import Waves from 'node-waves'
	export default {
		name: 'Home',
		components: {

		},
		data() {
			return {
				user: {},
				role: {},
				resouces: [],
			}
		},
		created() {
			this.role = JSON.parse(sessionStorage.getItem('role'))
			this.resouces = JSON.parse(sessionStorage.getItem('resouces'))
			this.initMenu();
			this.go('/index')
		},
		mounted() {
			this.user = JSON.parse(sessionStorage.getItem('user'))
			this.$notify({
				title: '欢迎登录',
				message: '欢迎使用大冶招商局信息填报系统，如有问题建议请联系管理员。',
				position: 'top-right',
				type: 'success'
			});
		},
		methods: {
			logout() {
				sessionStorage.removeItem('token')
				sessionStorage.removeItem('user')
				this.go('/login')
			},
			initMenu() {
				if ("undefined" == typeof jQuery) throw new Error("jQuery plugins need to be before this file");
				$.AdminCompass = {}, $.AdminCompass.options = {
					leftSideBar: {
						scrollColor: "rgba(0,0,0,0.5)",
						scrollWidth: "4px",
						scrollAlwaysVisible: !1,
						scrollBorderRadius: "0",
						scrollRailBorderRadius: "0"
					},
					dropdownMenu: {
						effectIn: "fadeIn",
						effectOut: "fadeOut"
					}
				}, $.AdminCompass.leftSideBar = {
					activate: function() {
						var t = this,
							s = $(".body"),
							r = $(".overlay");
						$(window).on("click", function(e) {
							var a = $(e.target);
							"i" === e.target.nodeName.toLowerCase() && (a = $(e.target).parent()), !a
								.hasClass(
									"bars") && t.isOpen() && 0 === a.parents("#leftsidebar").length && (a
									.hasClass(
										"js-right-sidebar") || r.fadeOut(), s.removeClass("overlay-open"))
						}), $.each($(".menu .list li.active"), function(e, a) {
							var t = $(a).find("a:eq(0)");
							t.addClass("toggled"), t.next().show()
						}), $(".menu-toggle").on("click", function(e) {
							var a = $(this),
								t = a.next();
							if ($(a.parents("ul")[0]).hasClass("list")) {
								var s = $(e.target).hasClass("menu-toggle") ? e.target : $(e.target)
									.parents(
										".menu-toggle");
								$.each($(".menu-toggle.toggled").not(s).next(), function(e, a) {
									$(a).is(":visible") && ($(a).prev().toggleClass("toggled"), $(
										a).slideUp())
								})
							}
							a.toggleClass("toggled"), t.slideToggle(320)
						}), t.checkStatuForResize(!0), $(window).resize(function() {
							t.checkStatuForResize(!1)
						}), Waves.attach(".menu .list a", ["waves-block"]), Waves.init()
					},
					checkStatuForResize: function(e) {
						var a = $(".body"),
							t = $(".navbar .navbar-header .bars"),
							s = a.width();
						e && a.find(".content, .sidebar").addClass("no-animate").delay(1e3).queue(function() {
							$(this).removeClass("no-animate").dequeue()
						}), s < 1170 ? (a.addClass("ls-closed"), t.fadeIn()) : (a.removeClass("ls-closed"), t
							.fadeOut())
					},
					isOpen: function() {
						return $(".body").hasClass("overlay-open")
					}
				}, $(".boxs-close").on("click", function() {
					$(this).parents(".card").addClass("closed").fadeOut()
				});

				$(function() {
					$.AdminCompass.leftSideBar.activate();
					$(".ls-toggle-btn").on("click", function() {
						$(".body").toggleClass("ls-toggle-menu")
					})
					$(".form-control").on("focus", function() {
						$(this).parent(".input-group").addClass("input-group-focus")
					}).on("blur", function() {
						$(this).parent(".input-group").removeClass("input-group-focus")
					});

					function removeActiveClass(node) {
						node.className = '';
					}

					let menus = document.querySelectorAll('.ml-menu li');
					menus.forEach(function(value, index) {
						value.addEventListener('click', function(e) {
							Array.prototype.forEach.call(document.querySelectorAll('.ml-menu li'),
								removeActiveClass);
							value.className = 'active';
						})
					});
				})
			}
		}
	}
</script>
<style>
	.role-name {
		background-image: -webkit-linear-gradient(45deg, #f35626, #feab3a);
		color: #f35626;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		-webkit-animation: hue6sinfinitelinear;
	}

	.el-form-item__label {
		color: darkslategray;
	}

	.el-form {
		padding: 20px 10px;
	}
</style>
